/*
  Please note this CSS is currently in   prototype form. We'll implement a cleaned up version in Web Starter Kit.
*/

.paper-snackbar {
  /*transition-property: opacity, bottom, left, right, width, margin, border-radius;
  transition-duration: 0.5s;
  transition-timing-function: ease;*/
  font-family: Roboto;
  font-size: 14px;
  min-height: 3vh;
  background-color: #323232;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  line-height: 22px;
  padding: 18px 24px;
  bottom: 0;
  opacity: 1;
  width: 100vw;
}

@media (min-width: 640px) {
  .paper-snackbar {
    /*
    Desktop:
      Single-line snackbar height: 48 dp tall
      Minimum width: 288 dp
      Maximum width: 568 dp
      2 dp rounded corner
      Text: Roboto Regular 14 sp
      Action button: Roboto Medium 14 sp, all-caps text
      Default background fill: #323232 100%
    */

    min-width: 288px;
    max-width: 568px;
    display: inline-flex;
    border-radius: 2px;
    margin: 24px;
    bottom: 0;
    position: relative;
    left: -50%;

  }

  .snackbar-wrapper {
      position: absolute;
      left: 50%;
      bottom:0;
  }
}

@include media($mobile) {
  .paper-snackbar {
  /*
  Mobile:
    Single-line snackbar height: 48 dp
    Multi-line snackbar height: 80 dp
    Text: Roboto Regular 14 sp
    Action button: Roboto Medium 14 sp, all-caps text
    Default background fill: #323232 100%
  */
    left: 0px;
    right: 0px;
  }

  .snackbar-wrapper {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
  }
}

.paper-snackbar .action {
  background: inherit;
  display: inline-block;
  border: none;
  font-size: inherit;
  text-transform: uppercase;
  color: #ffeb3b;
  margin: 0px 0px 0px 24px;
  padding: 0px;
  min-width: min-content;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-active {
  transform: translateX(10px);
  opacity: 0;
}

.slide-leave-active {
	@include animation-name(slideOutUp);
	@include animation-duration(300ms);

	@include animation-timing-function(ease-in-out);
    @include animation-fill-mode(forwards);
}

.slide-enter-active  {
	@include animation-name(slideUp);

	@include animation-duration(300ms);

	@include animation-timing-function(ease-in-out);
}

@include keyframes (slideUp) {
	0% {
		transform: translateY(100%);
	}
	50%{
		transform: translateY(4%);
	}
	100% {
		transform: translateY(0%);
	}
}

@include keyframes (slideOutUp) {
	100% {
		transform: translateY(100%);
	}
	0% {
		transform: translateY(0%);
	}
}
