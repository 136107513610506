@import "bower_components/bourbon/app/assets/stylesheets/bourbon";
@import "bower_components/neat/app/assets/stylesheets/neat";
@import "mixins";
@import "snackbar";
@import "spinner";
@import "materialselect";
@import "customselect";
@import "node_modules/vue2-timepicker/dist/VueTimepicker";

body {
    font-family: 'Roboto', sans-serif;
    background-size: cover;
    background-repeat: norepeat;
    color-adjust: exact;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    &.modal-open {
        overflow: hidden;
    }
}
h1 {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}
h2 {
    text-align: center;
    width: 100%;
}
#app {
    width: 60%;
    margin: 0 auto;
    background-color: #f8f9fa;
    min-height: 250px;
}
@include media($tablet) {
    #app {
        width: 80%;
    }
};
@include media($mobile) {
    #app {
        width: 100%;
    }
}

.card {
    background-color: white;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}
.add-padding { padding: 10px; }
input.multiselect {
    border-color: #e8e8e8;
    border-radius: 2px;
    box-sizing: inherit;
}
input.multiselect.date {
    border-color: #e8e8e8;
    border-radius: 5px;
    box-sizing: inherit;
    border-style: solid;
    border-width: 1px;
}
.input-field {
    font-size: x-large;
    width: 100%;
    margin: 10px auto;
    padding: 10px;
}
.input-field-small,
.label-small {
    font-size: medium;
    width: 100%;
    margin: 5px auto;
    padding: 5px;
}
.label-large {
    font-size: x-large;
    width: 100%;
    margin: 5px auto;
    padding: 5px;
}
.warning {
    color: brown;
}
.warning-small {
    font-size: medium;
    width: 100%;
    margin: 5px auto;
    padding: 5px;
    color: red;
    font-weight: bold;
    text-align: right;
}
.input-field-label {
    font-size: medium;
    color: gray;
    padding-bottom: -10px auto;
}

.checkbox-div{
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
}
.check-field-input {
    width: 20px;
    height: 20px;
    margin-top: 0px;
    vertical-align: middle;
    display: inline-block;
}
.check-field-label {
    color: black;
    font-size: large;
    height: 20px;
}
.picklist {
    position: absolute;
    float: right;
    background-color: rgba(255,255,255,1);
    margin-top: 0px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 5px 15px #777;
    border: solid 1px #ccc;
    z-index: 10; /*Else it appears behind the selectors*/
}
.picklist-item {
    list-style-type: none;
    font-size: x-large;
    padding: 5px;
    padding-right: 50px;
}
.primary-button {
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
    font-size: 1.1em;
}

.flat-button {
    background-color: white;
    color: black;
    padding: 10px;
    border: solid 1px lightgrey;
    min-width: 82px;
}
.flat-button-small {
    background-color: white;
    color: black;
    padding: 10px;
    border: solid 1px lightgrey;
    min-width: 22px;
}
.destructive {
    background-color: red;
    color: white;
    font-weight: bold;
}
.unstyled-list {
    list-style: none;
}


.button-span-columns-6 {
    @include span-columns(6);
    margin-right: 2px;
    margin-left: 2px;
}

.fill-green {
    background-color: #2ecc71;
    color: white;
}

.fill-red {
    background-color: #cc2e34;
    color: white;
}

.fill-blue {
    background-color: #1e3d77;
    color: white;
}
.fill-soft-blue {
    background-color: #7792c7;
    color: white;
}

table {
    width:60%;
    margin: 0 auto;
    margin-bottom: 10px;
    font-size: 1.5em;
    background-color: white;
}
table.large {
    width:100%;
    margin: 0 auto;
    margin-bottom: 10px;
    font-size: 1.5em;
}

// these two have to be global since scoped scss won't apply
// to datepicker (it's native style is applied post-"mounted")
.datepicker-input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    resize: vertical;
}
.error {
    & * { color: #8B0000; }
    input {
        background-color: #F2DEDE;
        border: 1px dotted #b94a48;
    }
}

@include media($mobile) {
    table {
        width: 80%;
    }
    .input-field {
        font-size: xx-large;
    }
    .primary-button {
        padding: 20px;
        font-size: 1em;
    }
}

th.half {
    text-align: left;
    @include span-columns(6);
}
th {
    text-align: left;
    padding-bottom: 10px;
}

td.half {
    text-align: left;
    @include span-columns(6);
}
td.half-centered {
    text-align: center;
    vertical-align: middle;
    @include span-columns(6);
}
.centered{
    text-align: center;
    vertical-align: middle;
}
td {
    text-align: left;
}
@include media($mobile) {
    td,
    td.half {
        text-align: right;
    }
}

tr {
    margin-bottom: 10px;
    border:  solid lightgrey;
    border-width: 1px 0;
}

tr:first-child {
  border-top: none;
}
tr:last-child {
  border-bottom: none;
}
tr.border-bottom td{
    margin-left: 0px;
    margin-right: 0px;
    padding-top: 10px;
    border-bottom: 1px solid #EEE;
}
.group-select,
.customer-select {
    width: 100%;
    font-family: inherit;
    background-color: transparent;
    width: 100%;
    padding: $select-padding;
    font-size: $select-font-size;
    border: none;
    border-bottom: 1px solid $select-border-color;
}

.header {
    width:100%;
    font-weight: bold;
    padding: 5px;
    background-color:  #dddddd;
}
.clickable {
    cursor: pointer;
}
.preformatted-text {
    white-space: pre-line;
    font-size: medium;
    word-wrap: break-word;
}
.testing {
    color:plum;
}
@media screen and (max-width: 600px) {
    table {
        width: 90%;
        table-layout: fixed;
    }
    h1 {
        font-size: 1.25em;
        display: inline-block;
        margin-bottom: 5px;
    }
    h2 {
        font-size: 1.05em;
        display: inline-block;
        margin-top: 5px;
    }
    .flat-button {
        background-color: white;
        color: black;
        padding: 10px 1px;
        border: solid 1px lightgrey;
        min-width: 75px;
    }
}
