html {
  box-sizing: border-box;
}

*, *::after, *::before {
  box-sizing: inherit;
}

/*
  Please note this CSS is currently in   prototype form. We'll implement a cleaned up version in Web Starter Kit.
*/
.paper-snackbar {
  /*transition-property: opacity, bottom, left, right, width, margin, border-radius;
  transition-duration: 0.5s;
  transition-timing-function: ease;*/
  font-family: Roboto;
  font-size: 14px;
  min-height: 3vh;
  background-color: #323232;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  line-height: 22px;
  padding: 18px 24px;
  bottom: 0;
  opacity: 1;
  width: 100vw;
}

@media (min-width: 640px) {
  .paper-snackbar {
    /*
    Desktop:
      Single-line snackbar height: 48 dp tall
      Minimum width: 288 dp
      Maximum width: 568 dp
      2 dp rounded corner
      Text: Roboto Regular 14 sp
      Action button: Roboto Medium 14 sp, all-caps text
      Default background fill: #323232 100%
    */
    min-width: 288px;
    max-width: 568px;
    display: inline-flex;
    border-radius: 2px;
    margin: 24px;
    bottom: 0;
    position: relative;
    left: -50%;
  }
  .snackbar-wrapper {
    position: absolute;
    left: 50%;
    bottom: 0;
  }
}

@media screen and (max-width: 70em) {
  .paper-snackbar {
    /*
  Mobile:
    Single-line snackbar height: 48 dp
    Multi-line snackbar height: 80 dp
    Text: Roboto Regular 14 sp
    Action button: Roboto Medium 14 sp, all-caps text
    Default background fill: #323232 100%
  */
    left: 0px;
    right: 0px;
  }
  .snackbar-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.paper-snackbar .action {
  background: inherit;
  display: inline-block;
  border: none;
  font-size: inherit;
  text-transform: uppercase;
  color: #ffeb3b;
  margin: 0px 0px 0px 24px;
  padding: 0px;
  min-width: min-content;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter, .slide-fade-leave-active {
  transform: translateX(10px);
  opacity: 0;
}

.slide-leave-active {
  -webkit-animation-name: slideOutUp;
  -moz-animation-name: slideOutUp;
  animation-name: slideOutUp;
  -webkit-animation-duration: 300ms;
  -moz-animation-duration: 300ms;
  animation-duration: 300ms;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.slide-enter-active {
  -webkit-animation-name: slideUp;
  -moz-animation-name: slideUp;
  animation-name: slideUp;
  -webkit-animation-duration: 300ms;
  -moz-animation-duration: 300ms;
  animation-duration: 300ms;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

@-webkit-keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  50% {
    transform: translateY(4%);
  }
  100% {
    transform: translateY(0%);
  }
}

@-moz-keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  50% {
    transform: translateY(4%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  50% {
    transform: translateY(4%);
  }
  100% {
    transform: translateY(0%);
  }
}

@-webkit-keyframes slideOutUp {
  100% {
    transform: translateY(100%);
  }
  0% {
    transform: translateY(0%);
  }
}

@-moz-keyframes slideOutUp {
  100% {
    transform: translateY(100%);
  }
  0% {
    transform: translateY(0%);
  }
}

@keyframes slideOutUp {
  100% {
    transform: translateY(100%);
  }
  0% {
    transform: translateY(0%);
  }
}

.spinner {
  position: relative;
  left: -50%;
  margin: 0 auto;
  width: 100px;
}

.spinner:before {
  content: '';
  display: block;
  padding-top: 100%;
}

.spinner-wrapper {
  position: absolute;
  left: 50%;
  top: 100px;
}

.circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes color {
  100%,
  0% {
    stroke: #d62d20;
  }
  40% {
    stroke: #0057e7;
  }
  66% {
    stroke: #008744;
  }
  80%,
  90% {
    stroke: #ffa700;
  }
}

/* ==========  Select Field Variables ========== */
/* ==========  Select Field ========== */
/* Style Select Field */
select {
  font-family: inherit;
  background-color: transparent;
  width: 100%;
  padding: 4px 0;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid rgba(0,0,0, 0.12);
}

/* Remove focus */
select:focus {
  outline: none;
}

/* Hide label */
/* Use custom arrow */
.mdl-selectfield select {
  appearance: none;
}

.mdl-selectfield {
  font-family: 'Roboto','Helvetica','Arial',sans-serif;
  position: relative;
}

.mdl-selectfield:after {
  top: 2em;
  right: 0.8em;
  /* Styling the down arrow */
  width: 0;
  height: 0;
  padding: 0;
  content: '';
  border-left: .25em solid transparent;
  border-right: .25em solid transparent;
  border-top: 0.375em solid rgba(0,0,0, 0.12);
  pointer-events: none;
}

@media screen and (max-width: 70em) {
  .mdl-selectfield {
    font-family: 'Roboto','Helvetica','Arial',sans-serif;
    position: relative;
  }
  .mdl-selectfield:after {
    position: absolute;
    top: 2.3em;
    right: 1em;
    /* Styling the down arrow */
    width: 0;
    height: 0;
    padding: 0;
    content: '';
    border-left: .25em solid transparent;
    border-right: .25em solid transparent;
    border-top: 0.375em solid rgba(0,0,0, 0.12);
    pointer-events: none;
  }
}

/* ==========  Select Field Variables ========== */
.customselect {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: 'Roboto','Helvetica','Arial',sans-serif;
  position: relative;
}

.customselect:after {
  position: absolute;
  floating: left;
  top: 1.6em;
  right: 0.8em;
  /* Styling the down arrow */
  width: 0;
  height: 0;
  padding: 0;
  content: '';
  border-left: .25em solid transparent;
  border-right: .25em solid transparent;
  border-top: 0.375em solid rgba(0,0,0, 0.12);
  pointer-events: none;
}

@media screen and (max-width: 70em) {
  .customselect {
    font-family: 'Roboto','Helvetica','Arial',sans-serif;
    position: relative;
  }
  .customselect:after {
    position: absolute;
    floating: left;
    top: 1.7em;
    right: 1em;
    /* Styling the down arrow */
    width: 0;
    height: 0;
    padding: 0;
    content: '';
    border-left: .25em solid transparent;
    border-right: .25em solid transparent;
    border-top: 0.375em solid rgba(0,0,0, 0.12);
    pointer-events: none;
  }
}

.customselect-field {
  width: 100%;
  font-family: inherit;
  background-color: transparent;
  width: 100%;
  padding: 15px;
  font-size: 23px;
  border: none;
  border-bottom: 1px solid rgba(0,0,0, 0.12);
}

.customselect-box-wrapper {
  position: absolute;
  margin-top: 64px;
  width: 38%;
  z-index: 1000;
}

@media screen and (max-width: 70em) {
  .customselect-box-wrapper {
    position: absolute;
    margin-top: 64px;
    width: 95%;
    z-index: 1000;
  }
}

.customselect-box {
  position: relative;
  left: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  overflow: hidden;
  margin-bottom: 10px;
  z-index: 10;
}

.customselect-option {
  width: 100%;
  padding: 20px;
  font-size: 23px;
  display: block;
  text-decoration: none;
  /* no underline */
}

.inverted {
  padding: 0;
  margin: 0;
  font-size: 23px;
  color: inherit;
  font-weight: bold;
}

.customselect-disablebox {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
}

.vue__time-picker {
  display: inline-block;
  position: relative;
  font-size: 1em;
  width: 10em;
  font-family: sans-serif;
  vertical-align: middle;
}

.vue__time-picker * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.vue__time-picker input.display-time {
  border: 1px solid #d2d2d2;
  width: 10em;
  height: 2.2em;
  padding: .3em .5em;
  font-size: 1em;
}

.vue__time-picker input.has-custom-icon {
  padding-left: 1.8em;
}

.vue__time-picker input.display-time.invalid:not(.skip-error-style) {
  border-color: #c03;
  outline-color: #c03;
}

.vue__time-picker input.display-time.disabled, .vue__time-picker input.display-time:disabled {
  color: #d2d2d2;
}

.vue__time-picker .controls {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  -webkit-box-orient: horizontal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  pointer-events: none;
}

.vue__time-picker .controls, .vue__time-picker .controls > * {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
}

.vue__time-picker .controls > * {
  cursor: pointer;
  width: auto;
  -webkit-box-orient: vertical;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 .35em;
  color: #d2d2d2;
  line-height: 100%;
  font-style: normal;
  pointer-events: auto;
  -webkit-transition: color .2s,opacity .2s;
  transition: color .2s,opacity .2s;
}

.vue__time-picker .controls > :hover {
  color: #797979;
}

.vue__time-picker .controls > :active, .vue__time-picker .controls > :focus {
  outline: 0;
}

.vue__time-picker .controls .char {
  font-size: 1.1em;
  line-height: 100%;
  -webkit-margin-before: -.15em;
}

.vue__time-picker .custom-icon {
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 1.8em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  pointer-events: none;
}

.vue__time-picker .controls img, .vue__time-picker .controls svg, .vue__time-picker .custom-icon img, .vue__time-picker .custom-icon svg {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  border: 0;
  outline: 0;
  max-width: 1em;
  height: auto;
}

.vue__time-picker .time-picker-overlay {
  z-index: 4;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.vue__time-picker-dropdown, .vue__time-picker .dropdown {
  position: absolute;
  z-index: 5;
  top: calc(2.2em + 2px);
  left: 0;
  background: #fff;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
  width: 10em;
  height: 10em;
  font-weight: 400;
}

.vue__time-picker-dropdown {
  position: fixed;
  z-index: 100;
}

.vue__time-picker-dropdown.drop-up, .vue__time-picker .dropdown.drop-up {
  top: auto;
  bottom: calc(2.2em + 1px);
}

.vue__time-picker-dropdown .select-list, .vue__time-picker .dropdown .select-list {
  width: 10em;
  height: 10em;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.vue__time-picker-dropdown .select-list:active, .vue__time-picker-dropdown .select-list:focus, .vue__time-picker .dropdown .select-list:active, .vue__time-picker .dropdown .select-list:focus {
  outline: 0;
}

.vue__time-picker-dropdown ul, .vue__time-picker .dropdown ul {
  padding: 0;
  margin: 0;
  list-style: none;
  outline: 0;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0.00001px;
  flex: 1 1 0.00001px;
  overflow-x: hidden;
  overflow-y: auto;
}

.vue__time-picker-dropdown ul.apms, .vue__time-picker-dropdown ul.minutes, .vue__time-picker-dropdown ul.seconds, .vue__time-picker .dropdown ul.apms, .vue__time-picker .dropdown ul.minutes, .vue__time-picker .dropdown ul.seconds {
  border-left: 1px solid #fff;
}

.vue__time-picker-dropdown ul li, .vue__time-picker .dropdown ul li {
  list-style: none;
  text-align: center;
  padding: .3em 0;
  color: #161616;
}

.vue__time-picker-dropdown ul li:not(.hint):not([disabled]):focus, .vue__time-picker-dropdown ul li:not(.hint):not([disabled]):hover, .vue__time-picker .dropdown ul li:not(.hint):not([disabled]):focus, .vue__time-picker .dropdown ul li:not(.hint):not([disabled]):hover {
  background: rgba(0, 0, 0, 0.08);
  color: #161616;
  cursor: pointer;
}

.vue__time-picker-dropdown ul li:not([disabled]).active, .vue__time-picker-dropdown ul li:not([disabled]).active:focus, .vue__time-picker-dropdown ul li:not([disabled]).active:hover, .vue__time-picker .dropdown ul li:not([disabled]).active, .vue__time-picker .dropdown ul li:not([disabled]).active:focus, .vue__time-picker .dropdown ul li:not([disabled]).active:hover {
  background: #41b883;
  color: #fff;
}

.vue__time-picker-dropdown ul li[disabled], .vue__time-picker-dropdown ul li[disabled]:hover, .vue__time-picker .dropdown ul li[disabled], .vue__time-picker .dropdown ul li[disabled]:hover {
  background: transparent;
  opacity: .3;
  cursor: not-allowed;
}

.vue__time-picker-dropdown .hint, .vue__time-picker .dropdown .hint {
  color: #a5a5a5;
  cursor: default;
  font-size: .8em;
}

body {
  font-family: 'Roboto', sans-serif;
  background-size: cover;
  background-repeat: norepeat;
  color-adjust: exact;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

body.modal-open {
  overflow: hidden;
}

h1 {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

h2 {
  text-align: center;
  width: 100%;
}

#app {
  width: 60%;
  margin: 0 auto;
  background-color: #f8f9fa;
  min-height: 250px;
}

@media screen and (min-width: 60em) and (max-width: 80em) {
  #app {
    width: 80%;
  }
}

@media screen and (max-width: 70em) {
  #app {
    width: 100%;
  }
}

.card {
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.add-padding {
  padding: 10px;
}

input.multiselect {
  border-color: #e8e8e8;
  border-radius: 2px;
  box-sizing: inherit;
}

input.multiselect.date {
  border-color: #e8e8e8;
  border-radius: 5px;
  box-sizing: inherit;
  border-style: solid;
  border-width: 1px;
}

.input-field {
  font-size: x-large;
  width: 100%;
  margin: 10px auto;
  padding: 10px;
}

.input-field-small,
.label-small {
  font-size: medium;
  width: 100%;
  margin: 5px auto;
  padding: 5px;
}

.label-large {
  font-size: x-large;
  width: 100%;
  margin: 5px auto;
  padding: 5px;
}

.warning {
  color: brown;
}

.warning-small {
  font-size: medium;
  width: 100%;
  margin: 5px auto;
  padding: 5px;
  color: red;
  font-weight: bold;
  text-align: right;
}

.input-field-label {
  font-size: medium;
  color: gray;
  padding-bottom: -10px auto;
}

.checkbox-div {
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
}

.check-field-input {
  width: 20px;
  height: 20px;
  margin-top: 0px;
  vertical-align: middle;
  display: inline-block;
}

.check-field-label {
  color: black;
  font-size: large;
  height: 20px;
}

.picklist {
  position: absolute;
  float: right;
  background-color: white;
  margin-top: 0px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 5px 15px #777;
  border: solid 1px #ccc;
  z-index: 10;
  /*Else it appears behind the selectors*/
}

.picklist-item {
  list-style-type: none;
  font-size: x-large;
  padding: 5px;
  padding-right: 50px;
}

.primary-button {
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  font-size: 1.1em;
}

.flat-button {
  background-color: white;
  color: black;
  padding: 10px;
  border: solid 1px lightgrey;
  min-width: 82px;
}

.flat-button-small {
  background-color: white;
  color: black;
  padding: 10px;
  border: solid 1px lightgrey;
  min-width: 22px;
}

.destructive {
  background-color: red;
  color: white;
  font-weight: bold;
}

.unstyled-list {
  list-style: none;
}

.button-span-columns-6 {
  float: left;
  display: block;
  margin-right: 2.3576520234%;
  width: 48.8211739883%;
  margin-right: 2px;
  margin-left: 2px;
}

.button-span-columns-6:last-child {
  margin-right: 0;
}

.fill-green {
  background-color: #2ecc71;
  color: white;
}

.fill-red {
  background-color: #cc2e34;
  color: white;
}

.fill-blue {
  background-color: #1e3d77;
  color: white;
}

.fill-soft-blue {
  background-color: #7792c7;
  color: white;
}

table {
  width: 60%;
  margin: 0 auto;
  margin-bottom: 10px;
  font-size: 1.5em;
  background-color: white;
}

table.large {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 10px;
  font-size: 1.5em;
}

.datepicker-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical;
}

.error * {
  color: #8B0000;
}

.error input {
  background-color: #F2DEDE;
  border: 1px dotted #b94a48;
}

@media screen and (max-width: 70em) {
  table {
    width: 80%;
  }
  .input-field {
    font-size: xx-large;
  }
  .primary-button {
    padding: 20px;
    font-size: 1em;
  }
}

th.half {
  text-align: left;
  float: left;
  display: block;
  margin-right: 2.3576520234%;
  width: 48.8211739883%;
}

th.half:last-child {
  margin-right: 0;
}

th {
  text-align: left;
  padding-bottom: 10px;
}

td.half {
  text-align: left;
  float: left;
  display: block;
  margin-right: 2.3576520234%;
  width: 48.8211739883%;
}

td.half:last-child {
  margin-right: 0;
}

td.half-centered {
  text-align: center;
  vertical-align: middle;
  float: left;
  display: block;
  margin-right: 2.3576520234%;
  width: 48.8211739883%;
}

td.half-centered:last-child {
  margin-right: 0;
}

.centered {
  text-align: center;
  vertical-align: middle;
}

td {
  text-align: left;
}

@media screen and (max-width: 70em) {
  td,
  td.half {
    text-align: right;
  }
}

tr {
  margin-bottom: 10px;
  border: solid lightgrey;
  border-width: 1px 0;
}

tr:first-child {
  border-top: none;
}

tr:last-child {
  border-bottom: none;
}

tr.border-bottom td {
  margin-left: 0px;
  margin-right: 0px;
  padding-top: 10px;
  border-bottom: 1px solid #EEE;
}

.group-select,
.customer-select {
  width: 100%;
  font-family: inherit;
  background-color: transparent;
  width: 100%;
  padding: 15px;
  font-size: 23px;
  border: none;
  border-bottom: 1px solid rgba(0,0,0, 0.12);
}

.header {
  width: 100%;
  font-weight: bold;
  padding: 5px;
  background-color: #dddddd;
}

.clickable {
  cursor: pointer;
}

.preformatted-text {
  white-space: pre-line;
  font-size: medium;
  word-wrap: break-word;
}

.testing {
  color: plum;
}

@media screen and (max-width: 600px) {
  table {
    width: 90%;
    table-layout: fixed;
  }
  h1 {
    font-size: 1.25em;
    display: inline-block;
    margin-bottom: 5px;
  }
  h2 {
    font-size: 1.05em;
    display: inline-block;
    margin-top: 5px;
  }
  .flat-button {
    background-color: white;
    color: black;
    padding: 10px 1px;
    border: solid 1px lightgrey;
    min-width: 75px;
  }
}
