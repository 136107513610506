/* ==========  Select Field Variables ========== */
$color-black: "0,0,0";
$select-background-color: transparent;
$select-border-color: unquote("rgba(#{$color-black}, 0.12)") !default;
$select-font-size: 16px;
$select-color: unquote("rgba(#{$color-black}, 0.26)") !default;
$select-padding: 4px;

/* ==========  Select Field ========== */

/* Style Select Field */
select {
  font-family: inherit;
  background-color: transparent;
  width: 100%;
  padding: $select-padding 0;
  font-size: $select-font-size;
  border: none;
  border-bottom: 1px solid $select-border-color;
}

/* Remove focus */
select:focus {
  outline: none}

/* Hide label */

/* Use custom arrow */
.mdl-selectfield select {appearance: none}
.mdl-selectfield {
  font-family: 'Roboto','Helvetica','Arial',sans-serif;
  position: relative;
    &:after {
        //position: absolute;
        top: 2em;
        right: 0.8em;
        /* Styling the down arrow */
        width: 0;
        height: 0;
        padding: 0;
        content: '';
        border-left: .25em solid transparent;
        border-right: .25em solid transparent;
        border-top: .375em solid $select-border-color;
        pointer-events: none;
    }
}
@include media($mobile) {
    .mdl-selectfield {
      font-family: 'Roboto','Helvetica','Arial',sans-serif;
      position: relative;
        &:after {
            position: absolute;
            top: 2.3em;
            right: 1em;
            /* Styling the down arrow */
            width: 0;
            height: 0;
            padding: 0;
            content: '';
            border-left: .25em solid transparent;
            border-right: .25em solid transparent;
            border-top: .375em solid $select-border-color;
            pointer-events: none;
        }
    }
}
