/* ==========  Select Field Variables ========== */
$color-black: "0,0,0";
$select-background-color: transparent;
$select-border-color: unquote("rgba(#{$color-black}, 0.12)") !default;
$select-font-size: 23px;
$select-color: unquote("rgba(#{$color-black}, 0.26)") !default;
$select-padding: 15px;


.customselect {
    margin-top: 10px;
    margin-bottom: 10px;
      font-family: 'Roboto','Helvetica','Arial',sans-serif;
      position: relative;
        &:after {
            position: absolute;
            floating: left;
            top: 1.6em;
            right: 0.8em;
            /* Styling the down arrow */
            width: 0;
            height: 0;
            padding: 0;
            content: '';
            border-left: .25em solid transparent;
            border-right: .25em solid transparent;
            border-top: .375em solid $select-border-color;
            pointer-events: none;
        }
}
@include media($mobile) {
    .customselect {
      font-family: 'Roboto','Helvetica','Arial',sans-serif;
      position: relative;
        &:after {
            position: absolute;
            floating: left;
            top: 1.7em;
            right: 1em;
            /* Styling the down arrow */
            width: 0;
            height: 0;
            padding: 0;
            content: '';
            border-left: .25em solid transparent;
            border-right: .25em solid transparent;
            border-top: .375em solid $select-border-color;
            pointer-events: none;
        }
    }
}

.customselect-field {
    width: 100%;
    font-family: inherit;
    background-color: transparent;
    width: 100%;
    padding: $select-padding;
    font-size: $select-font-size;
    border: none;
    border-bottom: 1px solid $select-border-color;
}

.customselect-box-wrapper {
    position: absolute;;
    //top: 50px;
    margin-top: 64px;
    width: 38%;
    z-index: 1000;

}

@include media($mobile) {
    .customselect-box-wrapper {
        position: absolute;
        //top: 50px;
        margin-top: 64px;
        width: 95%;
        z-index: 1000;

    }
}

.customselect-box {
    position: relative;
    left: 0;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    overflow: hidden;
    margin-bottom: 10px;
    z-index: 10;
    //max-height: 454px;
}

.customselect-option {
    width: 100%;
    padding: 20px;
    font-size: $select-font-size;
    display: block;
    text-decoration: none; /* no underline */
}

.inverted {
    padding:0;
    margin:0;
    font-size: $select-font-size;
    color:inherit;
    font-weight: bold;
}

.customselect-disablebox {
    position:fixed;
    left:0;
    right:0;
    top:0;
    bottom:0;
    z-index: 5;
}
